import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'
import router from './router/router'
import request from "./utils/request";
Vue.prototype.request = request

import store from './store/store'
Vue.prototype.$store = store
// Vue.prototype.$baseUrl = 'https://fxsc.xdgm21.com'
Vue.prototype.$baseUrl = 'https://myqueen.sg'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter);

import Vue2OrgTree from 'vue2-org-tree'
// import '../libs/org-tree.less';
Vue.use(Vue2OrgTree)

// if(sessionStorage.getItem('token')){
//   console.log(sessionStorage.getItem('token'))
//   request.defaults.headers['token'] = JSON.parse(sessionStorage.getItem('token'));
// }
// axios.defaults.headers.post['Content-Type'] = 'application/json';

import i18n from './i18n/i18n';

new Vue({
  i18n, //很重要，别忘记
  render: h => h(App),
  router:router
}).$mount('#app')
